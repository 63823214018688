import * as React from 'react';
import Progress from './Progress';
import { Stack, IStackTokens } from 'office-ui-fabric-react/lib/Stack';
import { Dropdown, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import 'whatwg-fetch';
import { polyfill } from 'es6-promise';
import { ResponsiveMode } from 'office-ui-fabric-react/lib/utilities/decorators/withResponsiveMode';
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import { Image } from 'office-ui-fabric-react/lib/Image';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { IconButton } from 'office-ui-fabric-react/lib/Button';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import packagefile from '../../../package.json';
import { gzip } from 'pako'


polyfill();

export interface AppProps {
    title: string;
    isOfficeInitialized: boolean;
}
export interface AppState {
}

interface AppStateSpec {
    defintionGroups: IDropdownOption[],
    environments: IDropdownOption[],
    errorMsg: string,
    selectedDefGroup: IDropdownOption,
    selectedEnvironment: IDropdownOption,
    createTemplate: boolean,
    messageType: MessageBarType,
    totalDebits: number,
    totalCredits: number,
    hideDialog: boolean,
    dialogD365Url: string,
    dialogD365Name: string,
    displayProgressDefGroup: boolean,
    displayProgressUpload: boolean,
    dialogNameInputError: string,
    dialogUrlInputError: string,
    dialogAddMode: boolean,
    dialogTitle: string,
    dialogButtonName: string,
    oldDialogD365Url: string,
    oldDialogD365Name: string,
    versionInfo: string
}

export interface DefintionGroups {
    DefinitionGroups?: (GroupsEntity)[] | null;
}
export interface GroupsEntity {
    Group: string;
    Sheetname: string;
    Fields?: (string)[] | null;
    SQLFields?: (string)[] | null;
}

const environmentsKey: string = 'Environments';
const defintionGroupsKey: string = 'DefinitionGroups';
const environmentsSelectedKey: string = 'SelectedEnvironments';
const defintionGroupsSelectedKey: string = 'SelectedDefinitionGroups';
const defintionGroupTemplatesKey: string = 'DefinitionGroupTemplates';


export default class App extends React.Component<AppProps, AppState, AppStateSpec> {
    constructor(props, context) {
        super(props);
    }

    timesGetProcessingGroupsHasRun = 0;
    timesSendFileHasRun = 0;
    triedWithoutForceConsent = false;
    currentServiceCall = '';
    accessToken = '';
    domain = '';
    defintionGroups;
    filename = '';
    debitColumn;
    creditColumn;


    public state: AppStateSpec = {
        defintionGroups: [],
        environments: [],
        errorMsg: '',
        selectedDefGroup: null,
        selectedEnvironment: null,
        createTemplate: false,
        messageType: MessageBarType.info,
        totalDebits: 0.00,
        totalCredits: 0.00,
        hideDialog: true,
        dialogD365Url: '',
        dialogD365Name: '',
        displayProgressDefGroup: false,
        displayProgressUpload: false,
        dialogNameInputError: '',
        dialogUrlInputError: '',
        dialogAddMode: true,
        dialogTitle: '',
        dialogButtonName: '',
        oldDialogD365Url: '',
        oldDialogD365Name: '',
        versionInfo: ''
    };

    componentDidMount() {
        this.loadValuesFromStorage();
        this.setState({ versionInfo: "Version: " + packagefile.version })
    }

    loadValuesFromStorage() {
        let localEnv = JSON.parse(this.getFromStorage(environmentsKey));
        if (localEnv) {
            this.setState({ environments: localEnv })
        }

        let localSelEnv = JSON.parse(this.getFromStorage(environmentsSelectedKey));
        if (localSelEnv) {
            this.setState({ selectedEnvironment: localSelEnv });
        }


        let localDefGroups = this.getFromStorage(defintionGroupsKey);
        if (localDefGroups != null) {
            this.setState({ defintionGroups: JSON.parse(localDefGroups) })
        }

        let localSelDefGroups = this.getFromStorage(defintionGroupsSelectedKey);
        if (localSelDefGroups != null) {
            this.setState({ selectedDefGroup: JSON.parse(localSelDefGroups) })
        }        

        let localDefGroupsTemplates = this.getFromStorage(defintionGroupTemplatesKey);
        if (localDefGroupsTemplates != null) {
            this.defintionGroups = JSON.parse(localDefGroupsTemplates);
        }

        if (localSelDefGroups && localSelDefGroups.length > 0) {
            let localSelDefGroupsParsed = JSON.parse(localSelDefGroups);
            this.registerExcelCellEvent(localSelDefGroupsParsed.text);
            this.excelCalcTotals(localSelDefGroupsParsed.text);
        }
    }

    saveValuesToStorage() {
        if (this.state.environments.length > 0) {
            this.saveToStorage(environmentsKey, JSON.stringify(this.state.environments));
        }
        else {
            this.removeFromStorage(environmentsKey);
        }

        if (this.state.defintionGroups.length > 0) {
            this.saveToStorage(defintionGroupsKey, JSON.stringify(this.state.defintionGroups));
        }
        else {
            this.removeFromStorage(defintionGroupsKey);
        }
    }

    async callApiWithToken(options) {
        try {
            let token = await OfficeRuntime.auth.getAccessToken(options);
            this.accessToken = token;
            // get domain from preferred username in the token
            var parts = token.split(".");
            var partsToken = JSON.parse(atob(parts[1]));
            // DEBUG console.log('username: ' + partsToken.preferred_username);
            var email = partsToken.preferred_username;
            var domain = email.substring(email.lastIndexOf("@") + 1);
            this.domain = domain;
            // DEBUG console.log('domain: ' + domain);
            this.callApi();
        }
        catch (exception) {
            if (exception.code) {
                this.handleClientSideErrors(exception);
            }
            else {
                this.processError("EXCEPTION: " + JSON.stringify(exception));
            }
        }
    }

    async callApiAuthChallenge(authChallengeString) {
        try {
            let token = await OfficeRuntime.auth.getAccessToken({ authChallenge: authChallengeString });
            this.accessToken = token;
            // get domain from preferred username in the token
            var parts = token.split(".");
            var partsToken = JSON.parse(atob(parts[1]));
            // DEBUG console.log('username: ' + partsToken.preferred_username);
            var email = partsToken.preferred_username;
            var domain = email.substring(email.lastIndexOf("@") + 1);
            this.domain = domain;
            // DEBUG console.log('domain: ' + domain);
            this.callApi();
        }
        catch (exception) {
            if (exception.code) {
                this.handleServerSideErrors(exception);
            }
            else {
                this.processError("EXCEPTION: " + JSON.stringify(exception));
            }
        }
    }

    callApi() {
        if (this.currentServiceCall == 'values') {
            this.getDefintionGroupsFromService();
        }
        else {
            this.getFileAsyncInternal(); 
        }
    }

    getDefintionGroupsFromService() {
        this.setState({ displayProgressDefGroup: true });
        let error: boolean = false;
        window.fetch('/api/values?d365Url=' + this.state.selectedEnvironment.key + '&userDomain=' + this.domain, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + this.accessToken
            },
            cache: 'no-cache'
        }
        )
            .then(res => {
                error = !res.ok;
                return res.json();
            }).then(json => {
                if (error) {
                    this.handleServerSideErrors(json);
                }
                else {
                    this.fillDropDown(json);
                }

                this.setState({ displayProgressDefGroup: false });
            })
            .catch(err => {
                this.processError(err);
                this.setState({ displayProgressDefGroup: false });
            });           
        
    }

    getFileAsyncInternal() {
        Office.context.document.getFileAsync(Office.FileType.Compressed, { sliceSize: 4194304 },

            (result: any): void => {
                if (result.status == Office.AsyncResultStatus.Failed) {
                    this.processError(JSON.stringify(result));
                }
                else {
                    this.getAllSlices(result.value);
                }
            });
    }

    sendFileData(docxData) {
        Office.context.document.getFilePropertiesAsync((asyncResult) => {
            let fileUrl = asyncResult.value.url;
            this.filename = encodeURIComponent(fileUrl.split('\\').pop().split('/').pop());
            if (this.filename) {
                this.setState({ displayProgressUpload: true });
                let error: boolean = false;
                window.fetch('/api/fileupload?d365Url=' + this.state.selectedEnvironment.key + '&filename=' + this.filename + '&userDomain=' + this.domain, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + this.accessToken,
                        'Content-Encoding': 'gzip',
                        'Content-Type': 'application/json'
                    },
                    cache: 'no-cache',
                    body: gzip (this.encodeBase64(docxData))

                }
                )
                    .then(res => {
                        error = !res.ok;
                        return res.json();
                    }).then(json => {
                        if (error) {
                            console.log('Server Side Error: ' + json);
                            this.handleServerSideErrors(json);
                        }
                        else {
                            this.navigateD365WithID(json);
                        }
                        this.setState({ displayProgressUpload: false });
                    })
                    .catch(err => {
                        this.processError(err);
                        console.log('Other Error: ' + err);
                        this.setState({ displayProgressUpload: false });
                    });
            }
            else {
                this.processError("The file has not been saved yet.  Save the file and try again");
            }
        });
    }

    navigateD365WithID(result) {
        if (result != '') {
            //console.log('File URL  = ||' + this.fileUrl2 + '||');
            //console.log('File Name = ||' + this.filename + '||');
            //this.filename2 = encodeURIComponent(this.filename);
            //console.log('File Name encoded = ||' + this.filename2 + '||');
            //console.log('JSON RESULT FILE ID = ||' + result + '||');
            //console.log('PROCESSING GROUP = ||' + this.state.selectedDefGroup.text + '||');
            window.open('https://' + this.state.selectedEnvironment.key + '/?mi=LedgerImportAutoRunner2&FileId=' + result + '&Filename=' + this.filename + '&ProcessingGroup=' + this.state.selectedDefGroup.text);
        }
    }

    log = (text: string): (() => void) => (): void => console.log(text);

    render() {

        const {
            title,
            isOfficeInitialized,
        } = this.props;

        if (!isOfficeInitialized) {
            return (
                <Progress
                    title={title}
                    logo='../../Assets/images/AKALogo_Icon_80x80.png'
                    message='Please sideload your addin to see app body.'
                />
            );
        }

        const stackTokens: IStackTokens = { childrenGap: 13, padding: 13 };
        const hstackTokens: IStackTokens = { padding: 13 };

        return (
            <Stack>
                <Stack tokens={stackTokens} >
                    <Stack horizontal tokens={hstackTokens} >
                        <Stack.Item grow>
                            <Image
                                src='../../Assets/images/AKALogo.png'
                            />
                        </Stack.Item>
                        <Stack.Item grow>
                            <Text variant="xLarge" nowrap block>
                                DynAd Journal Import
                            </Text>
                        </Stack.Item>
                    </Stack>
                    <Separator />
                    <Stack horizontal horizontalAlign="space-between">
                        <Label>D365 environment</Label>
                        <Label></Label>
                        <IconButton                                
                            split={false}
                            styles={{
                                menuIcon: { width: 24, height: 24, padding: 0 }
                            }}
                            menuIconProps={{ iconName: 'Waffle' }}
                            menuProps={{
                                items: [
//Debug                                    {
//                                        key: 'viewinformation',
//                                        text: 'Write debug info to console',
//                                        iconProps: { iconName: 'PageList' },
//                                        onClick: this._viewInformation
//Debug                                    },
                                    {
                                        key: 'viewhistory',
                                        text: 'View history',
                                        iconProps: { iconName: 'PageList' },
                                        onClick: this._viewHistory
                                    },
                                    {
                                        key: 'clearcache',
                                        text: 'Clear cache',
                                        iconProps: { iconName: 'EmptyRecycleBin' },
                                        onClick: this._clearcache
                                    },
                                    {
                                        key: 'versioninfo',
                                        text: this.state.versionInfo
                                    }                                    
                                ]
                            }}
                        />                      
                    </Stack>
                    <Stack horizontal>
                        <Stack.Item grow>
                            <Dropdown
                                placeholder='Select an environment'
                                selectedKey={this.state.selectedEnvironment ? this.state.selectedEnvironment.key : undefined}
                                options={this.state.environments}
                                onChange={this._onUrlChange}
                                responsiveMode={ResponsiveMode.large}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <IconButton
                                iconProps={{ iconName: 'Add' }}
                                onClick={this._showDialog}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <IconButton
                                iconProps={{ iconName: 'Edit' }}
                                onClick={this._editDialog}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <IconButton
                                iconProps={{ iconName: 'Delete' }}
                                onClick={this._removeEnvironment}
                            />
                        </Stack.Item>
                    </Stack>
                    <Dialog
                        hidden={this.state.hideDialog}
                        onDismiss={this._closeDialog}
                        dialogContentProps={{
                            type: DialogType.normal,
                            title: this.state.dialogTitle,
                        }}
                        modalProps={{
                            isBlocking: false,
                            styles: { main: { maxWidth: 450 } }
                        }}
                    >
                        <TextField
                            label="Name"
                            placeholder='Enter a friendly name'
                            onChange={this._dialogNameChanged}
                            value={this.state.dialogD365Name}
                            required
                            errorMessage={this.state.dialogNameInputError} />
                        <TextField
                            label="D365 environment URL"
                            placeholder='Please enter url here'
                            value={this.state.dialogD365Url}
                            onChange={this._dialogUrlChanged}
                            prefix='https://'
                            required
                            errorMessage={this.state.dialogUrlInputError} />
                        <DialogFooter>
                            <PrimaryButton onClick={this._closeDialogAndAdd} text={this.state.dialogButtonName} />
                            <DefaultButton onClick={this._closeDialog} text="Cancel" />
                        </DialogFooter>
                    </Dialog>
                    <Separator />
                    <Label>Data project</Label>
                    <Stack horizontal>
                        <Stack.Item grow>
                            <Dropdown
                                placeholder='Select an option'
                                options={this.state.defintionGroups}
                                selectedKey={this.state.selectedDefGroup ? this.state.selectedDefGroup.key : undefined}
                                onChange={this._onDropDownChange}
                                responsiveMode={ResponsiveMode.large}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <IconButton
                                iconProps={{ iconName: 'Refresh' }}
                                onClick={this._getdefgroups}
                            />
                        </Stack.Item>
                    </Stack>
                    {this.state.displayProgressDefGroup && (
                        <Spinner size={SpinnerSize.medium} label="Loading Data Projects..." ariaLive="assertive" labelPosition="right" />
                    )
                    }
                    <PrimaryButton
                        text='Create template'
                        onClick={this._createTemplate}
                        allowDisabledFocus={true}
                    />
                    <Separator />                    
                    <Label>Total debits: ${this.state.totalDebits.toLocaleString(undefined,{minimumFractionDigits:2})}</Label>
                    <Label>Total credits: ${this.state.totalCredits.toLocaleString(undefined,{minimumFractionDigits:2 })}</Label>
                    <Label>Variance: ${(this.state.totalCredits - this.state.totalDebits).toLocaleString(undefined,{minimumFractionDigits:2 })}</Label>
                    <PrimaryButton
                        text='Upload'
                        onClick={this._upload}
                    />
                    {this.state.displayProgressUpload && (
                        <Spinner size={SpinnerSize.medium} label="Uploading Transactions..." ariaLive="assertive" labelPosition="right" />
                    )
                    }
                    {this.state.errorMsg &&
                        <MessageBar
                            messageBarType={this.state.messageType}
                            isMultiline={false}
                            onDismiss={this._clearMessages}
                            dismissButtonAriaLabel="Close"
                            truncated={true}
                            overflowButtonAriaLabel="See more">
                            {this.state.errorMsg}
                        </MessageBar>
                    }
                </Stack>                
            </Stack>
        );
    };

    _viewInformation = (): void => {
        console.log('protocol: ' + window.location.protocol);
        console.log('location: ' + window.location.hostname);
        console.log('port: ' + window.location.port);
        console.log('host: ' + window.location.host);
        console.log('origin: ' + window.location.origin);
        this.getUserName();
    };

    _upload = (): void => {
        this.currentServiceCall = 'upload';
        this.callApiWithToken({ allowConsentPrompt: false });
    };

    _clearcache = (): void => {
        localStorage.clear();
    };

    _viewHistory = (): void => {
        window.open('https://' + this.state.selectedEnvironment.key +  '/?mi=LedgerImportWorkspace');
    };


    _clearMessages = (): void => {
        this.setState({ errorMsg: ''});
    };

    _getdefgroups = (): void => {
        this.state.errorMsg = '';
        this.timesGetProcessingGroupsHasRun++;
        this.triedWithoutForceConsent = true;
        this.currentServiceCall = 'values';
        this.callApiWithToken({ forMSGraphAccess: false });
        this.saveValuesToStorage();        
    }

    _createTemplate = (): void => {
        if (this.state.selectedDefGroup.text) {
            this.createTemplate();
        }
    }

    _showDialog = (): void => {
        this.setState({
            hideDialog: false, dialogAddMode: true,
            dialogTitle: 'Add D365 Environment', dialogButtonName: 'Add',
            oldDialogD365Url: '', oldDialogD365Name: ''
        });
    };

    _editDialog = (): void => {
        this.setState({ dialogD365Url: this.state.selectedEnvironment.key });
        this.setState({ dialogD365Name: this.state.selectedEnvironment.text });
        this.setState({ oldDialogD365Url: this.state.selectedEnvironment.key });
        this.setState({ oldDialogD365Name: this.state.selectedEnvironment.text });
             
        this.setState({ hideDialog: false, dialogAddMode: false, dialogTitle: 'Edit D365 Environment', dialogButtonName: 'Save' });
    };

    _closeDialog = (): void => {
        this.setState({ hideDialog: true, dialogNameInputError: '', dialogUrlInputError: '' });
    };

    _dialogUrlChanged = (event): void => {
        this.setState({ dialogD365Url: event.target.value });
    }

    _dialogNameChanged = (event): void => {
        this.setState({ dialogD365Name: event.target.value });
    }

    _closeDialogAndAdd = (): void => {
        if (this.state.dialogD365Name.length == 0) {
            this.setState({ dialogNameInputError: "Name is required" });
        }
        else if (this.state.dialogD365Url.length == 0) {
            this.setState({ dialogUrlInputError: "URL is required" });
        }
        else if (this.state.dialogD365Url.charAt(this.state.dialogD365Url.length - 1) == '/') {
            this.setState({ dialogUrlInputError: "Remove '/' from end of URL" });
        }
        else if (this.state.dialogD365Url.charAt(this.state.dialogD365Url.length - 1) == ' ') {
            this.setState({ dialogUrlInputError: "Remove blank space from end of URL" });
        }
        else if (this.state.dialogD365Url.startsWith ("http")) {
            this.setState({ dialogUrlInputError: "Remove 'http://' at the beginning of the URL, this will be included automatically" });
        }
        else {

            this.setState({ hideDialog: true });
            let localEnvironments = this.state.environments;

            if (this.state.oldDialogD365Url == '') {
                localEnvironments.push({ key: this.state.dialogD365Url, text: this.state.dialogD365Name });
                this.setState({ selectedEnvironment: localEnvironments[localEnvironments.length-1] });
            }
            else {
                localEnvironments = [...this.state.environments];
                 
                let item = this.state.environments.filter((element) => {
                    return element.key == this.state.oldDialogD365Url;
                })[0];
                let itemIndex = this.state.environments.indexOf(item);
                item.key = this.state.dialogD365Url;
                item.text = this.state.dialogD365Name;
                localEnvironments[itemIndex] = item;
                this.setState({ selectedEnvironment: localEnvironments[itemIndex] });
                }
            
            this.setState({ environments: localEnvironments });
            
            this.setState({ dialogD365Url: '', dialogD365Name: '', dialogNameInputError: '', dialogUrlInputError: ''});

            this.saveValuesToStorage();
        }
    };

    _removeEnvironment = (): void => {
        let localEnvironments = this.state.environments.filter((element) => {
            return element.key != this.state.selectedEnvironment.key;
        });

        this.setState({ environments: localEnvironments.length == 0 ? [] : localEnvironments });

        if (localEnvironments.length == 0) {
            this.setState({ selectedDefGroup: null, defintionGroups: [] });
        }

        this.saveValuesToStorage();
    }

    _onUrlChange = (event, item): void => {

        try {
            this.setEnvironment(item);
            this._getdefgroups();
            if (item) {
                this.saveToStorage(environmentsSelectedKey, JSON.stringify(item));
            }
            else {
                this.removeFromStorage(environmentsSelectedKey);
            }
        }
        catch (e) {
            this.processError(e);
        }
    }

    setEnvironment(item) {
        this.setState({ selectedEnvironment: item });
    }

    _onDropDownChange = (event, item): void => {
        this.resetTotalsOnSelection(item);
        try {
            if (item) {
                this.saveToStorage(defintionGroupsSelectedKey, JSON.stringify(item));
            }
            else {
                this.removeFromStorage(defintionGroupsSelectedKey);
            }

            this.registerExcelCellEvent(item.text);
            this.excelCalcTotals(item.text);
        }
        catch (e) {
            this.processError(e);
        }
    }

    _refreshTotalsManual = (): void => {
        this.excelCalcTotals('');
    }

    async getUserName() {
        try {
            let debugTokenData = await OfficeRuntime.auth.getAccessToken({ forMSGraphAccess: true });
            console.log(debugTokenData)
            var parts = debugTokenData.split(".");
            var debugToken = JSON.parse(atob(parts[1]));
            console.log('username: ' + debugToken.preferred_username); 
            var email = debugToken.preferred_username;
            var domain = email.substring(email.lastIndexOf("@") + 1);
            console.log('domain: ' + domain); 
            return debugToken.preferred_username; 
            }
        catch (exception) {
            console.log('username exception: ' + exception.message);
        };
    };

    resetTotalsOnSelection(item) {
        this.setState({
            selectedDefGroup: item,
            totalDebits: 0.00,
            totalCredits: 0.00
        });
    }

    processError(error) {
        this.setState({
            errorMsg: this.state.errorMsg + ' ' + error,
            messageType: MessageBarType.error
        });
    }

    processExcelError(error: OfficeExtension.Error) {
        this.processError(error.code + ":" + error.message);
    }

    processResult(error) {
        this.setState({
            errorMsg: this.state.errorMsg + ' ' + error,
            messageType: MessageBarType.info
        });
    }

    handleServerSideErrors(result) {

        // Our special handling on the server will cause the result that is returned
        // from a AADSTS50076 (a 2FA challenge) to have a Message property but no ExceptionMessage.
        // var message = JSON.parse(result.responseText).Message;
        var message = result.Message;


        // Results from other errors (other than AADSTS50076) will have an ExceptionMessage property.
        // var exceptionMessage = JSON.parse(result.responseText).ExceptionMessage;
        var exceptionMessage = result.ExceptionMessage;

        // Microsoft Graph requires an additional form of authentication. Have the Office host 
	    // get a new token using the Claims string, which tells AAD to prompt the user for all 
	    // required forms of authentication.
        if (message) {
            if (message.indexOf('AADSTS50076') !== -1) {
                var claims = JSON.parse(message).Claims;
                var claimsAsString = JSON.stringify(claims);
                this.processError('DEBUG MESSAGE: ' + message);
                // this.processError('DEBUG CLAIMS: ' + claimsAsString);
                this.callApiAuthChallenge(claimsAsString);
                // this.dialogFallback;
                return;
            }
        }

        // Handle missing consent and scope (permission) related issues.
        if (exceptionMessage) {
            // Handle the case where consent has not been granted, or has been revoked.
            if (exceptionMessage.indexOf('AADSTS65001') !== -1) {
                this.processError('Please grant consent to this add-in to access your Microsoft Graph data.');
                /*
                    THE FORCE CONSENT OPTION IS NOT AVAILABLE IN DURING PREVIEW. WHEN SSO FOR
                    OFFICE ADD-INS IS RELEASED, REMOVE THE showResult LINE ABOVE AND UNCOMMENT
                    THE FOLLOWING LINE.
                */
                // getDataWithToken({ forceConsent: true });
            }

            // Handle Included no-cache for TEST only (no merge to prod)the case where an invalid scope (permission) was used in the on-behalf-of flow.
            else if (exceptionMessage.indexOf('AADSTS70011: The provided value for the input parameter scope is not valid.') !== -1) {
                this.processError('The add-in is asking for a type of permission that is not recognized.');
            }

            // Handle the case where the token that the add-in's client-side sends to it's 
            //          server-side is not valid because it is missing `access_as_user` scope (permission).
            else if (exceptionMessage.indexOf('Missing access_as_user.') !== -1) {
                this.processError('Microsoft Office does not have permission to get Microsoft Graph data on behalf of the current user.');
            }
            else {                
                this.processError(exceptionMessage);                
            }
        }
        else if (message){
            this.processError(message);
        }

        // Handle the case where the token sent to Microsoft Graph in the request for 
        //         data is expired or invalid.
        // If the token sent to MS Graph is expired or invalid, start the whole process over.
        else if (result.code === 'InvalidAuthenticationToken') {
            this.timesGetProcessingGroupsHasRun = 0;
            this.triedWithoutForceConsent = false;
            this._getdefgroups;
        }        
    }

    handleClientSideErrors(result) {
        switch (result.error.code) {

            case 13000:
                this.processError('The getAccessTokenAsync API is not supported by this add-in version');
                break;

            // Handle the case where user is not logged in, or the user cancelled, without responding, a
            //        prompt to provide a 2nd authentication factor. 
            case 13001:
                this.callApiWithToken({ allowConsentPrompt: true });
                break;

            // Handle the case where the user's sign-in or consent was aborted.
            case 13002:
                if (this.timesGetProcessingGroupsHasRun < 2) {
                    this.processResult('Your sign-in or consent was aborted before completion. Please try that operation again.');
                } else {
                    this.processError(result);
                }
                break;

            //  Handle the case where the user is logged in with an account that is neither work or school, 
            //        nor Micrososoft Account.
            case 13003:
                this.processError('Please sign out of Office and sign in again with a work or school account, or Microsoft account. Other kinds of accounts, like corporate domain accounts do not work.');
                break;

            case 13004:
                this.processError('13004: Invalid resource. The add-in manifest has not been configured correctly.');
                break;

            case 13005:
                this.processError('Invalid grant.  This usually means that Office has not bee pre-authorized to the add-in web service.');
                break;

            //  Handle an unspecified error from the Office host.
            case 13006:
                this.processError('Please save your work, sign out of Office, close all Office applications, and restart this Office application.');
                break;

            //  Handle the case where the Office host cannot get an access token to the add-ins 
            //        web service/application.
            case 13007:
                this.processError('That operation cannot be done at this time. Please try again later.');
                break;

            // Handle the case where the user tiggered an operation that calls `getAccessTokenAsync` 
            //        before a previous call of it completed.
            case 13008:
                this.processError('Please try that operation again after the current operation has finished.');
                break;

            // Handle the case where the add-in does not support forcing consent.
            case 13009:
                if (this.triedWithoutForceConsent) {
                    this.processResult('Please sign out of Office and sign in again with a work or school account, or Microsoft account.');
                } else {
                    this.callApiWithToken({ allowConsentPrompt: false });
                }
                break;

            case 13010:
                this.processError('The user is running the add-in in Office on Microsoft Edge or Internet Explorer. The user’s Office 365 domain, and the login.microsoftonline.com domain, are in a different security zones in the browser settings');
                break;

            case 13012:
                this.processError('The add-in is running on a platform that does not support the getAccessTokenAsync API. ');
                break;

            case 50001:
                this.processError('This error may indicate that the browser has cached an old copy of the office.js files');
                break;

            // Log all other client errors.
            default:
                this.processError('Error: ' + result.error.name + ": " + result.error.code + " - " + result.error.message);
                break;
        }
    }

    fillDropDown(result) {
        this.defintionGroups = JSON.parse(JSON.stringify(result)) as DefintionGroups;
        let localdefintionGroups: IDropdownOption[] = new Array();

        for (let i = 0; i < this.defintionGroups.DefinitionGroups.length; i++) {
            localdefintionGroups[i] = ({ key: i, text: this.defintionGroups.DefinitionGroups[i].Group });
        };

        this.setState({ defintionGroups: localdefintionGroups });

        this.saveToStorage(defintionGroupTemplatesKey, JSON.stringify(this.defintionGroups));
    }

    createTemplate() {
        Excel.run(async (context) => {
            let sheets = context.workbook.worksheets;
            let currentTemplate = this.currentTemplate('');
            let sheet = context.workbook.worksheets.getItem(currentTemplate.Sheetname);
            if (sheet) {
                sheet.name = currentTemplate.Sheetname + "_old";
                await context.sync();
            }

            
            sheet = sheets.add(currentTemplate.Sheetname);
            let range = sheet.getRangeByIndexes(0, 0, 1, currentTemplate.Fields.length);

            range.values = [currentTemplate.Fields];
            range.format.fill.color = "#0E62AB";
            range.format.font.name = "Sequi UI";
            range.format.font.color = "White";
            range.format.font.size = 9;
            range.format.autofitColumns();
            return context.sync().then(() => sheet.activate());
        }).catch((error) => this.processError(error));

        this.registerExcelCellEvent('');
    }

    currentTemplate(seletedDefGroup): GroupsEntity {

        if (seletedDefGroup != '' && this.defintionGroups) {
            return this.defintionGroups.DefinitionGroups.filter((element) => {
                return element.Group == seletedDefGroup;
            })[0];
        }
        else if (this.state.selectedDefGroup == null) {
            return null;
        }
        else {
            return this.defintionGroups.DefinitionGroups.filter((element) => {
                return element.Group == this.state.selectedDefGroup.text;
            })[0];
        }
    }

    registerExcelCellEvent(seletedDefGroup) {
        let eventResult;
        let currTemp = this.currentTemplate(seletedDefGroup);

        if (currTemp) {
            Excel.run((context) => {
                let worksheet = context.workbook.worksheets.getItemOrNullObject(currTemp.Sheetname);
                worksheet.onChanged.add((event): any => this.excelEventHandler(event));
                return context.sync();
            }).catch((error) => this.processExcelError(error));
        }
    }
    
    excelEventHandler(event: Excel.WorksheetChangedEventArgs) {
        this.excelCalcTotals('');
    }

    excelCalcTotals(seletedDefGroup) {
        let currTemp = this.currentTemplate(seletedDefGroup);
        if (currTemp) {
            Excel.run({ delayForCellEdit: true }, (context) => {
                let sheet = context.workbook.worksheets.getItemOrNullObject(currTemp.Sheetname);
                let usedRange = sheet.getUsedRange();
                let rowCount;
                let debitSum = 0.00, creditSum = 0.00, offsetCreditSum = 0.00, offsetDebitSum = 0.00, creditTotal = 0.00, debitTotal = 0.00;
                let saveRangeDebit;
                let saveRangeCredit;
                usedRange.load();
                //usedRange.format.fill.color = "pink";
                return context.sync(usedRange)
                    .then((usedRange) => {
                        if (usedRange) {
                            rowCount = usedRange.rowCount;
                            let findRanges = usedRange.getRow(0).findOrNullObject(currTemp.Fields[currTemp.SQLFields.indexOf('DEBITAMOUNT')], {
                                    completeMatch: true,
                                    matchCase: false
                                });
                            findRanges.load('address');
                            return context.sync(findRanges)
                        }
                    })
                    .then((findRanges) => {
                        if (findRanges && findRanges.address) {
                            let debitColumnAddress = findRanges.address.split(',')[0];
                            let debitColumnRange = sheet.getRange(debitColumnAddress);
                            debitColumnRange.load('columnIndex');
                            //debitColumnRange.format.fill.color = "blue";
                            return context.sync(debitColumnRange)
                        }
                    })
                    .then((debitColumnRange) => {
                        if (rowCount && debitColumnRange) {
                            let rangeDebit = sheet.getRangeByIndexes(1, debitColumnRange.columnIndex, rowCount - 1, 1);
                            saveRangeDebit  = rangeDebit;
                            let sumObjDebit = context.workbook.functions.sum(rangeDebit);
                            sumObjDebit.load();
                            let subODebit = sumObjDebit.load('value');
                            //rangeDebit.format.fill.color = "orange";
                            return context.sync(subODebit);
                        }
                    })
                    .then((subODebit) => {
                        if (subODebit) {
 
                            debitSum = subODebit.value;
                            this.setState({ totalDebits: debitSum });
                        }
                    })
                    .then(() => {
                        if (rowCount) {
                            let findRanges = usedRange.getRow(0).findOrNullObject(currTemp.Fields[currTemp.SQLFields.indexOf('CREDITAMOUNT')], {
                                completeMatch: true,
                                matchCase: false
                            });                            
                            findRanges.load('address');
                            return context.sync(findRanges)
                        }
                    })
                    .then((findRanges) => {
                        if (findRanges && findRanges.address) {
                            let creditColumnAddress = findRanges.address.split(',')[0];
                            let creditColumnRange = sheet.getRange(creditColumnAddress);
                            creditColumnRange.load('columnIndex');
                            return context.sync(creditColumnRange)
                        }
                    })
                    .then((creditColumnRange) => {
                        if (rowCount && creditColumnRange) {
                            let rangeCredit = sheet.getRangeByIndexes(1, creditColumnRange.columnIndex, rowCount - 1, 1);
                            saveRangeCredit = rangeCredit;
                            let sumObjCredit = context.workbook.functions.sum(rangeCredit);
                            sumObjCredit.load();
                            let subOCredit = sumObjCredit.load('value');
                            return context.sync(subOCredit);
                        }
                    })
                    .then((subOCredit) => {
                        if (subOCredit) {
                            creditSum = subOCredit.value;
                            this.setState({ totalCredits: creditSum });
                        }
                    })
                    // The following code handles single line accounting entries where the DB/CR columns should also be used in the DB/CR calculation 
                    // The SumIf function is used in this case
                    // This will work for both Credit and Debit columes in conjunction with the Offset Account Display value being filled in 
                    .then(() => {
                        if (rowCount) {
                            let findRanges = usedRange.getRow(0).findOrNullObject(currTemp.Fields[currTemp.SQLFields.indexOf('OFFSETACCOUNTDISPLAYVALUE')], {
                                completeMatch: true,
                                matchCase: false
                            });
                            findRanges.load('address');
                            return context.sync(findRanges)
                        }
                    })
                    .then((findRanges) => {
                        if (findRanges && findRanges.address) {
                            let offAcctDispAddress = findRanges.address.split(',')[0];
                            let offAcctDispRange = sheet.getRange(offAcctDispAddress);
                            offAcctDispRange.load('columnIndex');
                            return context.sync(offAcctDispRange)
                        }
                    })
                    .then((offAcctDispRange) => {
                        if (rowCount && offAcctDispRange) {
                            let offsetRangeAcctDisp = sheet.getRangeByIndexes(1, offAcctDispRange.columnIndex, rowCount - 1, 1);
                            let sumOffsetCredit = context.workbook.functions.sumIf(offsetRangeAcctDisp,"> ", saveRangeDebit);
                            sumOffsetCredit.load();
                            let sub1Credit = sumOffsetCredit.load('value');
                            let sumOffsetDebit = context.workbook.functions.sumIf(offsetRangeAcctDisp, "> ", saveRangeCredit);
                            sumOffsetDebit.load();
                            let sub2Credit = sumOffsetDebit.load('value');
                            return context.sync({ sub1Credit, sub2Credit });
                        }
                    })
                    .then((subVariables) => {
                        if (subVariables && subVariables.sub1Credit) {
                            offsetCreditSum = subVariables.sub1Credit.value;
                            creditTotal = offsetCreditSum + creditSum;
                            this.setState({ totalCredits: creditTotal });
                        }
                        if (subVariables && subVariables.sub2Credit) {
                            offsetDebitSum = subVariables.sub2Credit.value;
                            debitTotal = offsetDebitSum + debitSum;
                            this.setState({ totalDebits: debitTotal });
                        }
                    }) 
                    
            }).catch((error) => this.processError(error));
        }
    }

    // file utilities begin -->
    getAllSlices(file) {
        let sliceCount = file.sliceCount;
        let sliceIndex = 0;
        let docdata = [];
        const getSlice = () => {
            file.getSliceAsync(sliceIndex, (asyncResult: any): void => {
                if (asyncResult.status == "succeeded") {
                    docdata = docdata.concat(asyncResult.value.data);
                    sliceIndex++;
                    if (sliceIndex == sliceCount) {
                        file.closeAsync();
                        this.sendFileData(docdata);
                    }
                    else {
                        getSlice();
                    }
                }
                else {
                    file.closeAsync();
                    this.processError(JSON.stringify(asyncResult));

                }
            });
        };
        getSlice();
    }

    encodeBase64(docData) {
        var s = "";
        for (var i = 0; i < docData.length; i++)
            s += String.fromCharCode(docData[i]);
        return window.btoa(s);
    }

    // file utilities end -->

    // local storage functions start -->

    saveToStorage(key, value) {
        localStorage.setItem(key, value);
    }

    getFromStorage(key): string {
        let value = localStorage.getItem(key);
        return value;
    }

    removeFromStorage(key) {
        localStorage.removeItem(key);
    }

    // local storage functions end <--

    // Dialog API

    loginDialog;

    dialogFallback() {

        var url = "/azureadauth/login";
        this.showLoginPopup(url);
    }

    // This handler responds to the success or failure message that the pop-up dialog receives from the identity provider
    // and access token provider.
    processMessage(arg) {

        this.processResult("Message received in processMessage: " + JSON.stringify(arg));
        let message = JSON.parse(arg.message);

        if (message.status === "success") {
            // We now have a valid access token.
            this.loginDialog.close();
            this.processResult("Successfully authenticated user");
        } else {
            // Something went wrong with authentication or the authorization of the web application.
            this.loginDialog.close();
            this.processResult(["Unable to successfully authenticate user or authorize application. Error is: " + message.error]);
        }
    }

    showLoginPopup(url) {
        var fullUrl = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + url;

        // height and width are percentages of the size of the parent Office application, e.g., PowerPoint, Excel, Word, etc.
        Office.context.ui.displayDialogAsync(fullUrl,
            { height: 60, width: 30 }, function (result) {
                console.log("Dialog has initialized. Wiring up events");
                this.loginDialog = result.value;
                this.loginDialog.addEventHandler(Office.EventType.DialogMessageReceived, this.processMessage);
            });
    }
}